import Image from '@/components/atoms/Image';
import SwiperCore, { Autoplay } from 'swiper/core';
import Pagination from 'swiper/components/pagination';
import Navigation from 'swiper/components/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Pagination, Navigation]);
SwiperCore.use([Autoplay]);

import styles from './home.module.scss';
import cn from 'classnames';
import { useMemo } from 'react';

const baseUrl = `${process.env.APP_STRAPI_URL}`;

const BannerCarousel = ({ children, mobile, mobileBgUrl, bgUrl }) => {
  const [carouselIsActive, carouselList] = useMemo(() => {
    const flag = Array.isArray(mobileBgUrl) && mobileBgUrl.length > 1;
    var list: any = [];
    if (flag) {
      list = mobileBgUrl.map((e) => ({
        alt: e.alternativeText,
        url: baseUrl + e.url,
      }));
    }

    return [mobile && flag, list];
  }, [mobileBgUrl, bgUrl, mobile]);

  const backgroundUrl = useMemo(() => {
    if (mobile && mobileBgUrl?.[0]) return baseUrl + mobileBgUrl?.[0]?.url;

    if (bgUrl?.url) return baseUrl + bgUrl?.url;

    return '';
  }, [mobile, mobileBgUrl, bgUrl]);

  return (
    <>
      {mobile && carouselIsActive && (
        <div className={cn('is-hidden-desktop', styles['slider-container'])}>
          <div className={styles['slider-wrapper']}>
            <Swiper
              loop
              slidesPerView={1}
              // navigation
              autoplay={{
                delay: 2000,
              }}
              pagination={{ clickable: true }}
              breakpoints={{
                1024: {
                  slidesPerView: 3,
                },
              }}
            >
              {carouselList?.map((e: any, i: number) => {
                return (
                  <SwiperSlide key={i} className={styles['full-height']}>
                    <Image
                      size={'5by3'}
                      className={styles['full-height']}
                      fit={'cover'}
                      key={e}
                      alt={e.alt}
                      src={e.url}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      )}
      <div
        className={cn(
          styles.homeslide,
          !!carouselIsActive && mobile && styles['absolute-overlay'],
        )}
        style={{
          ...(!carouselIsActive && {
            backgroundImage: `url(${backgroundUrl})`,
          }),
        }}
      >
        {children}
      </div>
    </>
  );
};

export default BannerCarousel;
