import { gql } from 'graphql-request';

export const saveEmailForNeevReminder = gql`
  mutation saveNeevTalksReminderMail($email: String!) {
    saveNeevTalksReminderMail(email: $email) {
      status
      message
    }
  }
`;
