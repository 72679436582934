import Modal from '@/components/atoms/Modal';
import loadable from '@loadable/component';
import Google from '@/components/icons/Google';
import Button from '@/components/atoms/Button';
import cn from 'classnames';
import { useState } from 'react';
import styles from './styles.module.scss';
import { useSignIn } from '@/hooks/customer/useSignIn';
import { useCustomer } from '@/hooks/customer/useCustomer';
import { useCallback } from 'react';
import useNeevTalksReminder from '@/hooks/neev-talks/useNeevReminder';
import SvgIcon from '@/components/atoms/icon/SvgIcon';

const ModalContent = loadable(() => import('./ModalContent'));

export default function NeevReminderModal({ Content = 'Set Reminder' }: any) {
  const [openModal, setModal] = useState(false);
  const { isSignedIn } = useSignIn();
  const [{ customer }] = useCustomer();
  const { handleSetNeevTalksReminder } = useNeevTalksReminder();

  const handleClick = useCallback(async () => {
    //set gmail reminder if logged in user has email with gmail id
    if (
      isSignedIn() &&
      customer?.email &&
      String(customer.email).indexOf('@gmail.com') > -1
    ) {
      try {
        return await handleSetNeevTalksReminder({ email: customer.email });
      } catch (e) {
        console.log('Error', e);
      }
    }

    setModal(true);
  }, [customer, isSignedIn()]);

  return (
    <>
      <Button
        className={cn(
          'is-uppercase white-space-normal py-1 has-text-weight-medium',
          styles['cta-btn'],
          styles.reminder,
        )}
        onClick={handleClick}
      >
        <span className='mt-4'>
          <SvgIcon icon={'path-left'} size={'20'} />
        </span>
        {/* <Google size={24} /> */}
        <span className='px-2 primary-color'>{Content}</span>
        <span className='mt-4'>
          <SvgIcon icon={'path-right'} size={'20'} />
        </span>
      </Button>
      <Modal show={openModal} onClose={() => setModal(false)}>
        <ModalContent onClose={() => setModal(false)} />
      </Modal>
    </>
  );
}
