import { Link } from 'react-router-dom';
import SvgIcon from '@/components/atoms/icon/SvgIcon';
import cn from 'classnames';
import Icon from '@/components/atoms/Icon';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './home.module.scss';
import BannerCarousel from './BannerCarousel';
import { useState, useEffect } from 'react';
import CTAWrapper from './CTA';

const SlidePage = ({
  imageUrl,
  title,
  cta,
  desc,
  logo,
  scrollDown,
  carousel,
  name,
  scrollToName,
  socialIcons,
  mobileUrl,
  googleReminder,
  // new
  Heading,
  Description,
  bgUrl,
  mobileBgUrl,
  ...rest
}: any) => {
  const [mobile, setMobile] = useState(false);
  const CarouselFragment = BannerCarousel;
  // }
  // const { scrollNext, scrollPrev } = useFullPageContext();
  // useEffect(() => {
  //   //TODO - scroll pages
  //   const maxScroll = document.body.scrollHeight;
  //   var currentScrollPos = window.pageYOffset;
  //   const updateState = (e: any) => {
  //     // const innerHeight = window.innerHeight;
  //     // const currentScrollPos = window.pageYOffset;
  //     // const currenItem = currentScrollPos / innerHeight;

  //     // if (currentScrollPos >= window.innerHeight && currentScrollPos < maxScroll) {
  //     //   if (currenItem < scrollToName) {
  //     //     scroller.scrollTo(scrollToName, {
  //     //       duration: 300,
  //     //       smooth: true,
  //     //     })
  //     //   }
  //     // }
  //   };
  //   const scrollListener = () => {
  //     requestAnimationFrame(updateState);
  //   };

  //   const resizeListener = () => {
  //     setMobile(window.innerWidth <= 768);
  //   }
  //   setMobile(window.innerWidth <= 768);

  //   window.addEventListener('resize', resizeListener)
  //   window.addEventListener('wheel', scrollListener);
  //   return () => {
  //     window.removeEventListener('resize', resizeListener);
  //     window.removeEventListener('wheel', scrollListener);
  //   };
  // }, []);

  useEffect(() => {
    const resizeListener = () => {
      setMobile(window.innerWidth <= 768);
    };
    setMobile(window.innerWidth <= 768);
    window.addEventListener('resize', resizeListener);
  }, []);

  return (
    <div
      className={cn(
        'homepage has-text-centered has-text-white',
        styles['page-slide-root'],
      )}
    >
      <CarouselFragment
        {...{
          mobile,
          mobileBgUrl,
          bgUrl,
        }}
      >
        <div className='is-flex is-justify-content-center'>
          <div
            className={cn(
              'is-flex px-2 is-justify-content-center is-align-items-center is-flex-direction-column',
              styles.page,
              !logo && 'mt-auto',
            )}
          >
            {logo && (
              <h1 className={'mt-auto mb-4 pt-2'}>
                <Link to={logo.link} title={logo.title}>
                  <img src={logo.imageLink} alt={'Neev'} />
                </Link>
              </h1>
            )}
            {Heading && (
              <h2
                className={cn(
                  'is-uppercase mb-3',
                  mobile || logo ? 'mt-3' : 'mt-auto',
                )}
              >
                {Heading}
              </h2>
            )}
            {Description && <p className='mb-4 is-size-6'>{Description}</p>}
            <CTAWrapper
              {...{
                mobile,
                carousel,
                list: rest.CTA,
              }}
            />
            {socialIcons && (
              <div className={cn('is-flex is-align-items-center')}>
                <a
                  className={'mx-4'}
                  title='Neev Instagram'
                  target={'_blank'}
                  href={
                    'https://www.instagram.com/neev_by_vibhutikabra/?igshid=18xpp1tu1mg32'
                  }
                >
                  <Icon>
                    <FontAwesomeIcon size={'2x'} icon={faInstagram} />
                  </Icon>
                </a>
                <a
                  className={'mx-4'}
                  title='Neev Facebook'
                  target={'_blank'}
                  href={'https://www.facebook.com/Neevbyvibhutikabra'}
                >
                  <SvgIcon icon={'fb-white'} size={'32'} />
                </a>
              </div>
            )}
            {scrollDown && (
              <span
                className={cn(!socialIcons && 'mt-auto', 'mb-5')}
                // onClick={scrollNext}
              >
                {/* //TODO - Disabled as said by arjun */}
                {/* <SvgIcon icon={'scroll-down'} size={'16'} /> */}
              </span>
            )}
          </div>
        </div>
      </CarouselFragment>
    </div>
  );
};

export default SlidePage;
