import HomeSlidePage from './SlidePage';
import styles from './home.module.scss';
import FullPage from '../molecules/FullPage';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { useHomePageContext } from '@/hooks/useHome';

const homeSlideList = [
  {
    imageUrl: '/home/vibhuti_kabra.png',
    mobileUrl: '/home/banner1.jpg',
    scrollDown: true,
    name: 'slide1',
    scrollToName: 'slide2',
    socialIcons: true,
    title: 'Neev Live',
    desc: 'Our freshest collection, which translates to “I am because we are” in Zulu, comes packed with a profusion of prints and comfortable silhouettes, all in soft, summer-friendly colours.',
    cta: {
      icon: 'play-outline',
      text: 'Watch Now',
      link: '/neev-talks/live',
    },
    googleReminder: true,
  },
  {
    title: 'Summer 2020',
    desc: 'Our freshest collection, which translates to “I am because we are” in Zulu, comes packed with a profusion of prints and comfortable silhouettes, all in soft, summer-friendly colours.',
    imageUrl: '/home/saree.jpg',
    mobileUrl: '/home/banner2.png',
    cta: {
      text: 'Shop Now',
      link: '/collections/summer-2020.html',
    },
    scrollDown: true,
    name: 'slide2',
    scrollToName: 'slide3',
  },
  {
    name: 'slide3',
    title: '#trending',
    desc: ' Look at our hot selling designs to stay on top of the trend.',
    cta: {
      text: 'Check it out',
      link: '/women/fabriccategory/trending.html',
    },
    imageUrl: '/home/slide.png',
    mobileUrl: '/home/slide.png',
    carousel: [
      '/slider/slide1.jpeg',
      '/slider/slide2.JPG',
      '/slider/slide3.JPG',
    ],
    mobile: true,
  },
];

export default function HomePage(props) {
  const { seo, slidePageUrls } = props
  // const [{ SlidePage = [] }] = useHomePageContext();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  return (
    <div className={styles.container}>
      <Helmet>{seo?.title && <title>{seo?.title}</title>}</Helmet>
      {/* <FullPage fullPageScroll={false} duration={1000}> */}
      {slidePageUrls.map((e: any, i: number) => (
        <HomeSlidePage {...e} {...homeSlideList[i]} key={i} />
      ))}
      {/* </FullPage> */}
    </div>
  );
}
