import SvgIcon from '@/components/atoms/icon/SvgIcon';
import styles from './home.module.scss';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Button from '@/components/atoms/Button';
import Columns from '@/components/atoms/Columns';
import NeevReminderModal from './NeevReminder';
import { Fragment } from 'react';

const CTA = ({ mobile, carousel, Link: link, Content, iconLabel }) => {
  return (
    <Link to={link} className={cn('mb-4')}>
      <Button
        className={cn(
          'is-uppercase white-space-normal is-secondary py-1 has-text-weight-medium',
          styles.cta,
          carousel && mobile && styles['remove-margin'],
        )}
      >
        <span className='mt-4'>
          <SvgIcon icon={'path-left'} size={'20'} />
        </span>
        {iconLabel && (
          <SvgIcon
            style={{ fill: '#C4361C' }}
            icon={iconLabel}
            className='mr-3'
            size={'20'}
          />
        )}
        <span className={'pr-2 primary-color'}>{Content}</span>
        <span className='mt-4'>
          <SvgIcon icon={'path-right'} size={'20'} />
        </span>
      </Button>
    </Link>
  );
};

const CTAWrapper = ({ mobile, carousel, list = [] }) => {
  return (
    <Columns className='py-0'>
      {list.map((e: any, i: number) => (
        <Columns.Column key={i}>
          {e.googleReminder ? (
            <NeevReminderModal
              {...{
                ...e,
              }}
            />
          ) : (
            <CTA
              {...{
                mobile,
                carousel,
                ...e,
              }}
            />
          )}
        </Columns.Column>
      ))}
    </Columns>
  );
};

export default CTAWrapper;
