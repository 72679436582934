import { useAppContext } from '@/components/context/AppContext';
import useGraphQLRequest from '@/hooks/useGraphQLRequest';
import { saveEmailForNeevReminder } from '@/services/graphql/mutations/reminders/reminder.gql';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNeevToasts } from '@/components/context/toast';

const useNeevTalksReminder = () => {
  const methods = useForm();
  const { handleSubmit } = methods;
  const [subscribeNewsletter, { error, loading }] = useGraphQLRequest({
    query: saveEmailForNeevReminder,
  });

  const [{ handleLoader }] = useAppContext();
  const { addToast } = useNeevToasts();

  const handleSetNeevTalksReminder = useCallback(
    async ({ email, onClose }) => {
      if (email) {
        try {
          handleLoader(true);

          const d = await subscribeNewsletter({ email });
          const isSubsribed = d?.saveNeevTalksReminderMail?.status == true;

          if (isSubsribed) {
            addToast(`Yayy! ${d?.saveNeevTalksReminderMail?.message}`, {
              appearance: 'success',
              autoDismiss: true,
            });
          } else {
            addToast(
              d?.saveNeevTalksReminderMail?.message ||
                'Something went wrong. Please try again.',
              {
                appearance: 'error',
                autoDismiss: true,
              },
            );
          }

          onClose && onClose();

          handleLoader(false);
        } catch (e) {
          handleLoader(false);
          addToast('Something went. Please try again.', {
            appearance: 'error',
            autoDismiss: true,
          });
          console.log(e);
        }
      }
    },
    [subscribeNewsletter],
  );

  return {
    methods,
    handleSetNeevTalksReminder,
    error,
    isLoading: loading,
  };
};

export default useNeevTalksReminder;
